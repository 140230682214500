import axios from "axios";
import { configs } from "../config";

 const getRegularizationsApi = async (accessToken, empId, EmployeeId) => {
  let regularizationId = '';
  const options = {
    url: `${configs?.base_url}/services/data/v58.0/query/?q=SELECT Id,Log_In__c,Log_Out__c,Emp_Attendance__c,Name,Date1__c,EmpId__c,Status__c FROM Attendence__c WHERE Date1__c=LAST_N_DAYS:7 AND (Log_In__c=null or Log_Out__c =null) AND Date1__c < TODAY and Emp_Attendance__c='${EmployeeId}' `,
    method: "get",
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  };
  try {
    const response = await axios(options);
    regularizationId = response?.data?.records[0]?.Id;
    // console.log("regularizationId", regularizationId)
    return response;
  }
  catch (error) {
    console.log(error)
  }
};

export default getRegularizationsApi;